import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

function Kitchen() {
  const api = useAxios()
  const [orders, setOrders] = useState([])
  const [itemList, setItemList] = useState([])
  const [orderaccept, setOrderaccept] = useState(false)
  const [ready,setReady] = useState(false)

  const getOrders = async () => {
    const response = await api.get('/cook/orders')
    const readyToCook = response.data
    setOrders(response.data)
    const itemListArray = []
    for (const item of readyToCook) {
      const responses = await api.get(`/store/orderitem/${item.id}`)
      itemListArray.push(...responses.data)
    }
    setItemList(itemListArray)
  }

  const orderAccepted = (id) => {
    api.post(`/cook/acceptorder/${id}`).then(() => {
      setTimeout(() => {
        setOrderaccept(!orderaccept)
      }, 1000)
    })
  }

  const readyToServe = (id) => {
    api.post(`/cook/serveorder/${id}`).then(() => {
      setReady(!ready)
    })
  }

  useEffect(() => {
    getOrders()
    setInterval(() => {
      getOrders()
    },300000)
  }, [ready,orderaccept])

  return (
    <div className="order-page">
      {orders.length ?
      <div className="orders">
        {orders?.map(order => (
          <div key={order.id} className={`order order-new`}>
            <div className="order-header">
              <h2>Order From Table {order.id} By Waiter</h2>
            </div>
            {order.cook_accepted ? <div className="order-status-cook">Cooking...</div> : <div className="order-status-new">New Order</div>}
            

            <table className="order-items">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {itemList?.filter(filt => filt.order === order.id).map((item) => (
                  <tr key={item.id}>
                    <td>{item.product_name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!(order.cook_accepted) &&
              <div className='served-div'>
                <label className="switch">
                  <input onChange={() => orderAccepted(order.id)} type="checkbox" />
                  <span className="slider"></span>
                </label>
                <span>Accept</span>
              </div>
            }
            {(order.cook_accepted) &&
              <div className='served-div'>
                <label className="switch">
                  <input onChange={() => readyToServe(order.id)} defaultChecked={false} type="checkbox" />
                  <span className="slider"></span>
                </label>
                <span>Ready To Serve</span>
              </div>
            }
          </div>
        ))}
      </div>
      :
      <div className='no-orders'>
        <h3>No recently added orders found</h3>
      </div>
          }
    </div>
  );
}

export default Kitchen;
