import { createContext, useState, useEffect } from 'react'
import jwt_decode from "jwt-decode";
import { useNavigate} from 'react-router-dom'

const AuthContext = createContext()

export default AuthContext;


export const AuthProvider = ({children}) => {

    let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('cookauthtokens') ? JSON.parse(localStorage.getItem('cookauthtokens')) : null)
    let [user, setUser] = useState(()=> localStorage.getItem('cookauthtokens') ? jwt_decode(localStorage.getItem('cookauthtokens')) : null)
    let [loading, setLoading] = useState(true)
    const [loadings,setLoadings] = useState(false)
    const [pwerr,setPwerr] = useState(false)
    const [islogged,setIslogged] = useState(()=> localStorage.getItem('cookauthtokens') ? true : false)
    const baseURL = 'https://acecartapi.vipsnepal.com'
    const navigate = useNavigate()


    let loginUser = async (e)=> {
        e.preventDefault()
        let response = await fetch(`${baseURL}/cook/login/`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'username':e.target.username.value, 'password':e.target.password.value})
        })

        if(!response.ok){
            if(response.status == 500){
                alert("Server Error: Cook Login is required.")            }
        }

        setLoadings(true)
        let data = await response.json()
        if(response.status === 200){
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            setIslogged(prev=>!prev)
            localStorage.setItem('cookauthtokens', JSON.stringify(data))
            setLoadings(false)
        }
        else if(response.status === 401){
            setPwerr(true)
            setTimeout(() => {
                setPwerr(false)
              }, 4000)
            setLoadings(false)
        }
        else{
            alert('Server error. Please try again later.')
            setLoadings(false)
        }
    }

    


    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('cookauthtokens')
        navigate('/')
        setIslogged(false)
    }


    let contextData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser,
        islogged:islogged,
        baseURL:baseURL,
        loadings:loadings,
        pwerr:pwerr,
        setPwerr:setPwerr
    }


    useEffect(()=> {

        if(authTokens){
            setUser(jwt_decode(authTokens.access))
        }
        setLoading(false)

    }, [authTokens, loading])

    return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
}
