import { useContext, useState } from 'react';
import './App.css';
import AuthContext from './context/AuthContext';
import Kitchen from './pages/Kitchen';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

 
function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const {logoutUser} = useContext(AuthContext)
  return (
    <>
    <Button className='logout-button' color="danger" onClick={() => setModalOpen(true)}>Logout</Button>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader>Confirm Logout</ModalHeader>
        <ModalBody>
          Are you sure you want to log out?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { logoutUser()}}>Yes</Button>
          <Button color="secondary" onClick={() => setModalOpen(false)}>No</Button>
        </ModalFooter>
      </Modal>
    <Kitchen />
    </>
    // <div className="App">
    //   <button onClick={() => logoutUser()}>Logout</button> 
    // </div>
  );
}

export default App;
